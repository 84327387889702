/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, createFactory } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import countryList from "react-select-country-list";
import Select from "@material-ui/core/Select";
import "./App.css";
import { InputLabel, Typography, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import "./gel-grid.css";
import axios from "axios";
import "./gel-typography.css";
import { useHistory } from "react-router-dom";
import { v4 as uuid_v4 } from "uuid";
import auth from "./auth";
import Popper from "@material-ui/core/Popper";
const FileType  = require("file-type/browser");



const validator = require("email-validator");
const useStyles = makeStyles({
  root: {
    display: "flex",
    marginLeft: "30px",
  },
  mrgn: {
    margin: "10px",
    width: "150px",
  },
  lbl: {
    marginTop: "8px",
    marginBottom: "8px",
  },
});
function Home(props) {
  const classes = useStyles();
  const [freqs, setFreqs] = useState([]);
  const [data, setData] = useState([]);
  const [file, setFile] = useState();
  const [country, setCountry] = useState("");
  const [selectedFreq, setSelectedFreq] = useState("");
  const [lang, setLang] = useState("");
  const [mimeType, setMimeType] = useState(true);
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [formData, setFormData] = useState({
    reception_date: "",
    reception_time: "",
    receiver: "",
    antenna: "",
    signal: "",
    interference: "",
    noise: "",
    propagation: "",
    overall: "",
    remarks: "",
    province_city: "",
  });

  const options = countryList().getData();

  const Sinpo1 = [
    { value: 1, label: "Barely audible (1)" },
    { value: 2, label: "Poor (2)" },
    { value: 3, label: "Fair (3)" },
    { value: 4, label: "Good (4)" },
    { value: 5, label: "Excellent (5)" },
  ];
  const Sinpo2 = [
    { value: 1, label: "Extreme (1)" },
    { value: 2, label: "Severe (2)" },
    { value: 3, label: "Moderate (3)" },
    { value: 4, label: "Slight (4)" },
    { value: 5, label: "Nil (5)" },
  ];

  //Get frequency data from bucket
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(process.env.REACT_APP_databucket);
        const frequencyData = res.data;
        setData(frequencyData);
        return res;
      } catch (error) {
        console.log("HF data fetch error", error);
      }
    }
    fetchData();
  }, []);

  function filterData(value) {
    let freqs = [];
    let values = [];
    const freq = data.filter((array) => array.Language === value);
    freq.map((arr) => {
      values = arr.Frequency;
      return values;
    });

    values.map((hfvalues) => {
      const freqNo = +(hfvalues);
      return freqs.push({ frequency: freqNo, label: freqNo });
    });
    setLang(value);
   
    return freqs;
  }

  const handleLangChange = (event) => {
    setFreqs(filterData(event.target.value));
    
  };

  const handleFreqChange = (event) => {
    setSelectedFreq(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleFormDataChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: value,
    });
  };

  let ext;
  let filename;
  let id;
  // let renameFile;

  const whitelist = [
   'audio/basic',
    	'audio/mid',	 
    	'audio/mpeg',	
    	 'audio/x-aiff',	 
    	'audio/x-mpegurl',,	 
      'audio/vnd.dlna.adts',
     	'audio/ogg'	,
    	'audio/vorbis',	
    	'audio/vnd.wav',
      'audio/aac'
  ];

  const handleCapture =  (event) => {
    !mimeType ? setMimeType(true) : "";
    
    setFile(event.target.files[0]);
    setAnchorEl(null);

    
    
  };



 const handleClosePopper = () => {
  setAnchorEl(null);
 }

//  validate audio file
  if (file) {

    ext = file.name.slice(-3);
    FileType.fromBlob(file).then((res)=> {

      const fileType = res?.mime;
      if (!whitelist.includes(fileType) || !fileType ) {
        console.log('File submitted: Not an audio file');

        setMimeType(false);
      

      } else       {
        
        console.log('File submitted: ', fileType);



      }
    })
   
    
  }

  id = useRef(uuid_v4());

  filename = `${id.current}.${ext}`;



  const history = useHistory();
  const form = new FormData();

      //function to write audio file to s3 bucket if file is uploaded by user
  const writeToS3 = async (url, file, contentType) => {
        const newFile = renameFile(file, filename);
        setFile(newFile);
        await axios({
          method: "PUT",
          data: file,
          // url: httpbin,
          url: url,
          headers: {
            "content-type": contentType,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              console.log("axios s3 put response", response);
              history.push("/CardQSL");
            }
          })
          .catch((error) => {
            console.log(error);
            auth.logout(() => {
              history.push("/Error");
            });
    
            console.log(error);
          });
      };

      // function to rename audio file to matach uuid value
    const renameFile = (originalFile, newName) => {
      return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
    };
  function createForm(id, form, formData) {
    form.append("uuid", id);
    form.append("submission_date", new Date().toISOString());
    form.append("language", lang);
    form.append("frequency", selectedFreq);
    form.append("country", country);
    form.append("province_city", formData.province_city);
    form.append("reception_date", formData.reception_date);
    form.append("reception_time", formData.reception_time);
    form.append("receiver", formData.receiver);
    form.append("antenna", formData.antenna);
    form.append("signal", formData.signal);
    form.append("interference", formData.interference);
    form.append("noise", formData.noise);
    form.append("propagation", formData.propagation);
    form.append("overall", formData.overall);
    form.append("remarks", formData.remarks);
    form.append("name", formData.name);
    form.append("email", formData.email);
    form.append("file_name", filename);
    

  
    //if there is no file set file_name in form to ''
    if (!file) {
      form.set("file_name", "");
    }
    for (var key of form.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    return form;
  }

  // const endpoint = "https://eqsl.test.api.bbci.co.uk/eqslApi";
  const endpoint = process.env.REACT_APP_apiEndpoint;
  // console.log("endpoint", endpoint);

  //Post to get S3 signed URLs, for card and audio_file
  const handleSubmitForm = async (event) => {
    if (
      !formData.reception_date ||
      !lang ||
      !selectedFreq ||
      !formData.reception_time ||
      !country ||
      !formData.province_city
    ) {
      alert(
        "To submit a report, you need to provide at least the date, time, language, frequency and location"
      );
    } else if (!mimeType) {
      setAnchorEl(anchorEl? null : event.currentTarget);
    }
    
    
    else if (
      validator.validate(formData.email) ||
      (!formData.email && form.entries().next().done)
    ) {
      auth.login(() => {
        props.history.push("/Progress");
      });
      const datapost = createForm(id.current, form, formData);
      try {
        const response = await axios({
          method: "POST",
          data: datapost,
          // url: httpbin,
          url: endpoint,
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          console.log("post response", response);
          //if there is an audio file, extract signed s3 post url and write file to s3 bucket.

          if (file) {
            const signedUrl = response.data["preSigned URL"];
            const contentType = response.data["content-type"];
            writeToS3(signedUrl, file, contentType);
          } else {
            history.push("/CardQSL");
          }
        }
      } catch (error) {
        history.push("/Error");
        console.log("post error", error);
      }
    } else {
      alert("Please provide a valid email address");
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item sm={4} data-testid="column1">
          <InputLabel className={classes.lbl} data-testid="reception_date">
            Reception Date
          </InputLabel>
          <TextField
            style={{ width: "195px" }}
            variant="filled"
            name="reception_date"
            type="date"
            onChange={handleFormDataChange}
            inputProps={{
              "data-testid": "reception_date_selection",
              "aria-label": "reception_date_selection",
            }}
          />

          <InputLabel className={classes.lbl} data-testid="reception_time">
            Reception Time (UTC)
          </InputLabel>

          <TextField
            style={{ width: "195px" }}
            name="reception_time"
            type="time"
            variant="filled"
            onChange={handleFormDataChange}
            inputProps={{
              "data-testid": "reception_time_selection",
            }}
          />

          <InputLabel className={classes.lbl} data-testid="country">
            Country
          </InputLabel>
          <TextField
            name="country"
            select
            variant="filled"
            style={{ width: "195px" }}
            onClick={handleCountryChange}
            inputProps={{
              "data-testid": "country_selection",
            }}
            defaultValue={""}
          >
            {options.map((country, index) => (
              <MenuItem value={country.label} key={index}>
                {country.label}
              </MenuItem>
            ))}
          </TextField>
          <InputLabel className={classes.lbl} data-testid="province">
            Province/City
          </InputLabel>
          <TextField
            name="province_city"
            style={{ width: "195px" }}
            variant="filled"
            onChange={handleFormDataChange}
            inputProps={{
              "data-testid": "province_value",
            }}
          />

          <InputLabel className={classes.lbl} data-testid="service">
            BBC Service
          </InputLabel>
          <Select
            style={{ width: "195px" }}
            variant="filled"
            onClick={handleLangChange}
            defaultValue={""}
            inputProps={{
              "data-testid": "service_value",
            }}
          >
            {data.map((items, index) => (
              <MenuItem
                value={items.Language}
                key={index}
                data-testid="language"
              >
                {items.Language}
              </MenuItem>
            ))}
          </Select>

          <InputLabel data-testid="freq_label" className={classes.lbl}>
            Frequency (kHz)
          </InputLabel>
          <TextField
            style={{ width: "195px" }}
            select
            variant="filled"
            onClick={handleFreqChange}
            defaultValue={""}
            inputProps={{
              "data-testid": "freq_value",
            }}
          >
            {lang ? (
              freqs.map((array) => (
                <MenuItem
                  value={array.label}
                  key={array.frequency}
                  data-testid="freq_selection"
                >
                  {array.frequency}
                </MenuItem>
              ))
            ) : (
              <MenuItem>Please select a BBC Service first</MenuItem>
            )}
          </TextField>
        </Grid>

        <Grid item sm={4} data-testid="column2">
          <InputLabel className={classes.lbl} data-testid="receiver_label">
            Receiver
          </InputLabel>
          <TextField
            name="receiver"
            style={{ width: "195px" }}
            variant="filled"
            onChange={handleFormDataChange}
            inputProps={{
              "data-testid": "receiver_make",
            }}
          />
          <InputLabel className={classes.lbl} data-testid="antenna_label">
            Antenna
          </InputLabel>
          <TextField
            name="antenna"
            style={{ width: "195px" }}
            variant="filled"
            onChange={handleFormDataChange}
            inputProps={{
              "data-testid": "antenna",
            }}
          />
          <InputLabel className={classes.lbl} data-testid="signal_label">
            Signal (S)
          </InputLabel>
          <TextField
            name="signal"
            style={{ width: "195px" }}
            select
            variant="filled"
            value={formData.signal}
            onClick={handleFormDataChange}
            inputProps={{
              "data-testid": "signal_selection",
            }}
          >
            {" "}
            {Sinpo1.map((array) => (
              <MenuItem value={array.value} key={array.value}>
                {" "}
                {array.label}{" "}
              </MenuItem>
            ))}
          </TextField>{" "}
          <InputLabel className={classes.lbl} data-testid="interference_label">
            Interference (I)
          </InputLabel>
          <TextField
            style={{ width: "195px" }}
            select
            variant="filled"
            name="interference"
            value={formData.interference}
            onClick={handleFormDataChange}
            inputProps={{
              "data-testid": "interference_selection",
            }}
          >
            {" "}
            {Sinpo2.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {" "}
                {item.label}{" "}
              </MenuItem>
            ))}
          </TextField>{" "}
          <InputLabel className={classes.lbl} data-testid="noise_label">
            Noise (N)
          </InputLabel>
          <TextField
            style={{ width: "195px" }}
            select
            variant="filled"
            name="noise"
            value={formData.noise}
            onClick={handleFormDataChange}
            inputProps={{
              "data-testid": "noise_selection",
            }}
          >
            {Sinpo2.map((array) => (
              <MenuItem value={array.value} key={array.value}>
                {" "}
                {array.label}{" "}
              </MenuItem>
            ))}
          </TextField>
          <InputLabel className={classes.lbl} data-testid="propagation_label">
            Propagation (P)
          </InputLabel>
          <TextField
            style={{ width: "195px" }}
            select
            variant="filled"
            name="propagation"
            value={formData.propagation}
            onClick={handleFormDataChange}
            inputProps={{
              "data-testid": "propagation_selection",
            }}
          >
            {" "}
            {Sinpo2.map((array) => (
              <MenuItem value={array.value} key={array.value}>
                {" "}
                {array.label}{" "}
              </MenuItem>
            ))}
          </TextField>{" "}
        </Grid>
        <Grid item sm={4} data-testid="column3">
          <InputLabel className={classes.lbl} data-testid="o_label">
            Overall (O){" "}
          </InputLabel>
          <TextField
            style={{ width: "195px" }}
            select
            variant="filled"
            name="overall"
            value={formData.overall}
            onClick={handleFormDataChange}
            inputProps={{
              "data-testid": "overall_selection",
            }}
          >
            {Sinpo1.map((array, index) => (
              <MenuItem value={array.value} key={index}>
                {" "}
                {array.label}{" "}
              </MenuItem>
            ))}
          </TextField>
          <InputLabel
            style={{ marginTop: "8px", marginBottom: "8px" }}
            data-testid="remarks_label"
          >
            Remarks
          </InputLabel>
          <TextField
            multiline
            style={{ width: "195px" }}
            inputProps={{
              style: {
                height: "107px",
              },
              "data-testid": "remarks",
            }}
            maxRows={8}
            variant="filled"
            name="remarks"
            onChange={handleFormDataChange}
          ></TextField>{" "}
          <InputLabel className={classes.lbl} data-testid="name_label">
            Name
          </InputLabel>
          <TextField
            name="name"
            style={{ width: "195px" }}
            variant="filled"
            onChange={handleFormDataChange}
            inputProps={{
              "data-testid": "name",
            }}
          />
          <InputLabel style={{ marginTop: "8px", marginBottom: "8px" }}>
            E-mail
          </InputLabel>
          <TextField
            name="email"
            style={{ width: "195px" }}
            variant="filled"
            onChange={handleFormDataChange}
          />
          <div>
            <InputLabel style={{ marginTop: "8px", marginBottom: "-2px" }}>
              Choose audio file
            </InputLabel>
            
            <Button
              style={{ marginTop: "10px", width: "195px", height: "56px" }}
              variant="contained"
              color="primary"
            >
              {" "}
              <input accept="audio/*" onChange={handleCapture} type="file" />
            </Button>
           
           
            {/* <Dropzone
                open={open}
                setOpen={setOpen}
                setFiles={setFiles}
                files={files}
              /> */}

            <Button
              style={{ marginTop: "10px", width: "195px" }}
              variant="contained"
              color="secondary"
              onClick={handleSubmitForm}
            >
              Submit
            </Button>
            <Popper
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClosePopper}
            placement={"right"}
            style={{marginLeft: "10px", background: "red"}}
              >
                Only Audio Files Are Allowed!
            </Popper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
