import React from 'react';
import "./App.css";
import "./gel-grid.css";
import "./gel-typography.css";
import CardQSL from "./getCards.jsx";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Home from "./Home.jsx";
import Progress from "./Progress.js";
import Error from "./Error.js";
import ProtectedRoute from "./protected.route";
import bbclogo from "./bbcnewsWS.jpg";

function App() {
  return (
    <Router>
      <div>

        {/* for test site */}
        {/* <div>
          <header className="gel-canon-bold" style={{marginBottom:"30px"}}> BBC News | World Service </header>
        </div> */}

        {/* for live site */}
        <div>
          <a href="https://bbc.co.uk">
            <img src={bbclogo} className="App-logo" alt="bbc logo" />
          </a>
        </div>

        <div className="gel-layout__item gel-1/5">
          <h3 className="gel-canon-bold">eQSL Service</h3>
          <p className="gel-pica">
            {" "}
            Please use this form to submit your shortwave reception
            reports to the BBC World Service.
          </p>{" "}
          <p className="gel-pica">
            Find out more about how we handle your uploads and your personal
            data in our{" "}
            <a href="https://www.bbc.com/privacy/">Privacy Policy</a>.
          </p>{" "}
          <p className="gel-pica">
            For reception reports about the BBC Arabic Service for Gaza on 639 kHz MW/AM, please
            send them by email to worldservice.letters@bbc.co.uk.
          </p>
        </div>

        <div className="gel-layout__item gel-1/2">
          {" "}
            <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Home" component={Home} />
            <ProtectedRoute exact path="/Progress" component={Progress} />
            <ProtectedRoute exact path="/CardQSL" component={CardQSL} />
            <ProtectedRoute exact path="/Error" component={Error} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
