/* eslint-disable react/prop-types */
import React from "react";
import { Route } from "react-router-dom";
import auth from "./auth";

export default function ProtectedRoute({ component: Component, ...rest }) {

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated()) {
            console.log('is authenticated');
          return <Component {...props} />;
        } else {
            console.log('is not authenticated');
            
           
            props.history.push("/");

        }
      }}
    />
  );
}
