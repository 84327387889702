import React from "react";
import "./gel-typography.css";
import { CardActions, makeStyles, CircularProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import error from "./error.jpg";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles({
  root: {
maxWidth: "374px",
    marginLeft: "25px",
    flexWrap: "wrap",
  },
  title: {
    fontSize: 13,
  },
  media:{
    height: "50%",
    width:"50%",
    marginLeft:"55px"

  }
});

export default function Error() {
  const classes = useStyles();



  return (
  

<div>

    

    <Grid container
  direction="row"
  justifyContent="center"
  alignItems="center"
>
<Grid item sm={4} alignItems="center" >
<Card className={classes.root}>



<CardMedia 
component="img"
image={error}
className={classes.media}
/>
  <CardContent className={classes.title}>
  <Typography > Sorry. Something went wrong.Your report was not received.</Typography>
  
  </CardContent>
  <CardActions><Link to="/Home">Submit again.</Link></CardActions>

  
</Card>
</Grid>
</Grid>
</div>

);
 
     
      
   
 
}
