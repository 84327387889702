import React from 'react';
import Grid from '@material-ui/core/Grid';
import "./gel-typography.css";
import { CircularProgress} from "@material-ui/core";

export default function Progress() {
  
  return (
  
  <Grid container
  direction="row"
  justifyContent="center"
  alignItems="center"
>
<Grid item sm={4} alignItems="center" >

<CircularProgress color="secondary" size={50} style={{display: 'flex' , marginLeft: '70px', height: "150px"}}  />
<h3 className="gel-canon-regular"> Submitting reception report... </h3>

</Grid>
            
    </Grid>
  
  );
}
