import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import "./gel-typography.css";
import axios from "axios";
// import refreshWarning from './unSavedChanges';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  LineIcon,
  LivejournalIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import {
  FacebookShareButton,
  LineShareButton,
  MailruShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
} from "react-share";

const info = [
  "The new World piazza and blue-lit cyclorama, Broadcasting House 2012",
  "The elegant Art Deco reception in Broadcasting House, 1930s",
  "Her Majesty the Queen at the heart of the Newsroom on the day of the formal opening of Broadcasting House, 7 June 2013.",
  "Panorama view of the Broadcasting House complex, with All Souls church in the foreground, 2013.",
  "Classics of BBC Design: BBC 2 Colour 'cube', 1967, used in transmission with variations until 1974.",
  "Newsreader Sophie Raworth on the main news set of the BBC Newsroom, Broadcasting House 2013.",
  "Aerial view of Broadcasting House, 2012.",
  "Broadcasting House, the first purpose-built broadcast centre in the UK, 1933.",
  "The famous statues of Ariel and Prospero (1933) by Eric Gill on the front facade of Broadcasting House.",
  "Classics of BBC Design: BBC logotype, seen on letterheads, publicity, and BBC buildings, designed in the 1920s, in use until 1930s.",
  "The main Newsroom, at the heart of Broadcasting House, 2012.",
  "Construction of Broadcasting House, 1930.",
  "The latest technology in 1932: a mixing desk in use by the BBC Drama team, Broadcasting House.",
  "Classics of BBC Design: BBC Television Service 'bat wing' logo, 1953, designed by Abraham Games, used in transmission until 1960.",
  "Classics of BBC Design: BBC 1 Colour 'globe', 1969, used in transmission with variations until 1974.",
  "First recording of the Today programme in new Broadcasting House, December 2012.",
  "The BBC Far Eastern Relay Station in Singapore is located at Kranji on the northern edge of Singapore island and it is only ten direct miles from the previous location at Tebrau in Malaysia. Transmission from Kranji began in 1979 and ended in 2023.",
  "The BBC Far Eastern Relay Station in Singapore is located at Kranji on the northern edge of Singapore island and it is only ten direct miles from the previous location at Tebrau in Malaysia. Transmission from Kranji began in 1979 and ended in 2023.",
  "The BBC Far Eastern Relay Station in Singapore is located at Kranji on the northern edge of Singapore island and it is only ten direct miles from the previous location at Tebrau in Malaysia. Transmission from Kranji began in 1979 and ended in 2023.",
  "The BBC Atlantic Relay Station in Ascension Island The station made its first shortwave radio transmission on July 3rd, 1966, just days before England won the World Cup.",
  "The BBC Atlantic Relay Station in Ascension Island The station made its first shortwave radio transmission on July 3rd, 1966, just days before England won the World Cup.",
  "The BBC at Aseela site: The BBC Eastern Relay Station moved to its current location in Aseela, Oman, from Masirah Island, which was in constant use since 1967.",
  "A transmitter at BBC Aseela site: The BBC Eastern Relay Station moved to its current location in Aseela, Oman, from Masirah Island, which was in constant use since 1967.",
  "Transmitters at BBC Aseela site: The BBC Eastern Relay Station moved to its current location in Aseela, Oman, from Masirah Island, which was in constant use since 1967.",

];

const useCardStyles = makeStyles({
  root: {
    display: "flex",
    marginLeft: "25px",
    flexWrap: "wrap",
  },
  title: {
    fontSize: 12,
  },
  media: {
    height: "100%",
    width: "100%",
  },
});
export default function CardQSL() {
  const [url, setUrl] = useState("");
  const [infoNo, setInfoNo] = useState("");
  const endpoint = process.env.REACT_APP_apiEndpoint;
  const classes = useCardStyles();
  useEffect(() => {
    const getCard = async () => {
      try {
        const response = await axios.get(endpoint);
        const urlString = response.data.card_url.toString();
        setUrl(urlString);
        setInfoNo(response.data.imgNo);
      } catch (error) {
        console.log(error);
      }
    };
    getCard();
  }, [endpoint]);
  const para = info[infoNo];

  return (
    <div className={classes.root}>
      <Box display="inline-block">
        <Card>
          <CardHeader
            title="Your report was submitted successfully. 
            We appreciate your report. Thank you."
          />
          <div>
            <hr
              width="50%"
              align="left"
              style={{ marginTop: "-5px", marginLeft: "17px" }}
            ></hr>
          </div>
          <CardContent>
            <Typography className={classes.title}>
              <div className="gel-great-primer"> {para} </div>
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            className={classes.media}
            image={url}
            title="BBC eQSL Card"
          />

          <CardActions>
            <Link to="/Home" className="gel-canon-regular">
              Submit Another Reception Report
            </Link>
            <a href={url} className="gel-canon-regular">
              Download card
            </a>
          </CardActions>
          {/* <CardActions>
            {" "}
            <FacebookShareButton
              url={url}
              quote={para}
              hashtag="#BBCeQSL"
              className={classes.socialMediaButton}
            >
              <FacebookIcon size={36} />
            </FacebookShareButton>
            <LineShareButton
              url={url}
              quote={para}
              hashtag="#BBCeQSL"
              className={classes.socialMediaButton}
            >
              <LineIcon size={36} />
            </LineShareButton>
            <RedditShareButton
              url={url}
              quote={para}
              hashtag="#BBCeQSL"
              className={classes.socialMediaButton}
            >
              <RedditIcon size={36} />
            </RedditShareButton>
            <TelegramShareButton
              url={url}
              quote={para}
              hashtag="#BBCeQSL"
              className={classes.socialMediaButton}
            >
              <TelegramIcon size={36} />
            </TelegramShareButton>
            <TwitterShareButton
              url={url}
              quote={para}
              hashtag="#BBCeQSL"
              className={classes.socialMediaButton}
            >
              <TwitterIcon size={36} />
            </TwitterShareButton>
            <VKShareButton
              url={url}
              quote={para}
              hashtag="#BBCeQSL"
              className={classes.socialMediaButton}
            >
              <VKIcon size={36} />
            </VKShareButton>
          </CardActions> */}
        </Card>
      </Box>
    </div>
  );
}
